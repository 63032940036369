import React, { useState } from 'react'
import { NormalField } from 'reactstrap-form-fields'
import { FormGroup, Label, Input, FormText, Alert } from 'reactstrap'
import axios from 'axios'
import Button from './btn'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'

let ButtonBlock = styled.div`
  height:54px;
`

function resetForm(form) {
  // clearing inputs
  var inputs = form.getElementsByTagName('input');
  for (var i = 0; i<inputs.length; i++) {
      switch (inputs[i].type) {
          // case 'hidden':
          case 'text':
              inputs[i].value = '';
              break;
          case 'radio':
          case 'checkbox':
              inputs[i].checked = false;   
      }
  }

  // clearing selects
  var selects = form.getElementsByTagName('select');
  for (var i = 0; i<selects.length; i++)
      selects[i].selectedIndex = 0;

  // clearing textarea
  var text= form.getElementsByTagName('textarea');
  for (var i = 0; i<text.length; i++)
      text[i].value = '';

  return false;
}

let Form = ({name}) => {

  const [resultCode, setResultCode] = useState(0)
  const [messageColor, setMessageColor] = useState('info')
  const [showButton, setShowButton] = useState(true)
  const [showMessage, setShowMessage] = useState(false)

  function handleSubmit(event) {
    event.preventDefault()

    const Form = event.target
    const Button = Form.querySelector('button')

    const data = new FormData(Form)

    Button.disabled = true

    setTimeout(() => {
      axios({
        method: "post",
        url: "https://my.debttracker.ru/sendform",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
          //handle success
          Button.disabled = false

          if (response.data.result == 55) {
            resetForm(Form)
            setResultCode(55)
            setMessageColor('info')
          } else {
            setResultCode(3)
            setMessageColor('danger')
          }

          setShowMessage(true)
      })
      .catch(function (response) {
          //handle error
          Button.disabled = false
          setShowMessage(true)
          setResultCode(3)
          setMessageColor('danger')
      });
    }, 500);
  }

  return (
    <form name="message-form" method="POST" onSubmit={handleSubmit.bind(this)}>
      <input type="hidden" name="type" value={name}/>
      <NormalField label="Ваше имя" name="name" placeholder="Например: Андрей Николаевич"/>
      <NormalField label="Телефон" name="phone" placeholder="Например: 89001002030"/>
      <NormalField label="Организация" name="org" placeholder="Например: ООО «Новые системы»"/>
      {name == "contact-form" &&
      <FormGroup>
          <Label for="message">Сообщение:</Label>
          <Input type="textarea" name="message" id="message" />
      </FormGroup>
      }
      <ButtonBlock>
        {showButton && (
          <Button type="submit">Отправить</Button>
        )}
        <CSSTransition
          in={showMessage}
          timeout={300}
          classNames="alert"
          unmountOnExit
          onEnter={() => setShowButton(false)}
          onExited={() => setShowButton(true)}
        >
          <Alert color={messageColor} isOpen={showMessage} toggle={() => setShowMessage(false)}>
            {resultCode == 55 && (
              <span>Спасибо за обращение! Мы&nbsp;с&nbsp;Вами&nbsp;свяжемся</span>
            )}
            {resultCode != 55 && (
              <span>Возникла ошибка! Попробуйте&nbsp;еще&nbsp;раз</span>
            )}
          </Alert>
          
        </CSSTransition>
      </ButtonBlock>
    </form>
  )
}

export default Form
